<template>
  <div>
    <h1>uni-smart</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>

</style>
